<template>
  <div class="main-box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="130px" class="ruleForm" size="small" style="max-width: 650px">
      <el-form-item label="完善手机号送积分" prop="bind_mobile">
        <el-row type="flex" class="row-bg">
          <el-col :span="4"><el-checkbox v-model="info.is_bind_mobile" true-label="1" false-label="0">开启</el-checkbox></el-col>
          <el-col :span="20"><el-input v-model="info.bind_mobile" placeholder="请输入积分"><template slot="append">积分</template></el-input></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="主动收货送积分" prop="take_delivery">
        <el-row type="flex" class="row-bg">
          <el-col :span="4"><el-checkbox v-model="info.is_take_delivery" true-label="1" false-label="0">开启</el-checkbox></el-col>
          <el-col :span="20"><el-input v-model="info.take_delivery" placeholder="请输入积分"><template slot="append">积分</template></el-input></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="积分抵扣比例">
        <el-input v-model="info.integral_ratio" placeholder="请输入积分">
          <template slot="prepend">1元=</template>
          <template slot="append">积分，说明：为0则不能使用积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="订单使用积分上限">
        <el-input v-model="info.integral_upper_limit" placeholder="请输入积分"><template slot="append">积分，说明：单个订单最多使用积分数</template></el-input>
      </el-form-item>
      <el-form-item label="按订单金额送积分" prop="is_order_finish">
        <el-checkbox v-model="info.is_order_finish" true-label="1" false-label="0">开启</el-checkbox>
        <div class="list" v-if="info.order_finish && info.order_finish.length > 0">
          <div class="item mg-bt-10" v-for="(item,index) in info.order_finish" :key="index">
            <el-row type="flex" class="row-bg">
              <el-col :span="11">
                <el-input v-model="item.amount" placeholder="请输入金额">
                  <template slot="prepend">消费满</template>
                  <template slot="append">元</template>
                </el-input>
              </el-col>
              <el-col :span="11" style="padding: 0 10px; box-sizing: border-box">
                <el-input v-model="item.integral" placeholder="请输入积分">
                  <template slot="prepend">送</template>
                  <template slot="append">积分</template>
                </el-input>
              </el-col>
              <el-col :span="2">
                <el-button type="text" @click="delItem(index)">删除</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="info.order_finish.length < 5">
          <el-button type="text" @click="addItem()">添加</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      info: {
        is_bind_mobile: '',
        bind_mobile: 0,
        is_take_delivery: '',
        take_delivery: 0,
        is_order_finish: '',
        order_finish: [],
        integral_ratio: 0,
        integral_upper_limit: 0
      },
      rules: {
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    addItem() {
      this.info.order_finish.push({
        amount: '',
        integral: ''
      })
    },
    delItem(index) {
      this.info.order_finish.splice(index,1)
    },
    getInfo() {
      var _this = this
      this.$api.member.memberIntegralSetGet({}, res => {
        if(res.errcode == 0 && res.data) {
          _this.info = res.data
          _this.info.is_bind_mobile = (String)(_this.info.is_bind_mobile)
          _this.info.is_take_delivery = (String)(_this.info.is_take_delivery)
          _this.info.is_order_finish = (String)(_this.info.is_order_finish)
        }
      })
    },
    submitForm(formName) {
      var _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = _this.info
          this.$api.member.memberIntegralSetSave(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
